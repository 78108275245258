"use strict";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { createUniverseTransaction } from "state/sagas/utils/transaction";
import { call } from "typed-redux-saga";
import { refetchGQLQueries } from "uniswap/src/features/transactions/refetchGQLQueriesSaga";
import { createSaga } from "uniswap/src/utils/saga";
function* watchTransactions(params) {
  const { address, chainId, pendingDiff, apolloClient } = params;
  const info = pendingDiff[0].info;
  const transaction = createUniverseTransaction(info, chainId, address);
  if (!transaction) {
    return;
  }
  yield call(refetchGQLQueries, { transaction, apolloClient, activeAddress: address });
}
export const watchTransactionsSaga = createSaga(watchTransactions, "watchTransactions");
export function useWatchTransactionsCallback() {
  const appDispatch = useDispatch();
  return useCallback(
    (params) => {
      appDispatch(watchTransactionsSaga.actions.trigger(params));
    },
    [appDispatch]
  );
}
