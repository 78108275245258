import { useIsDarkMode } from "ui/src/hooks/useIsDarkMode";
import { colors, colorsDark, opacify } from "ui/src/theme/color";
import { isInterface } from "utilities/src/platform";
export function useShadowPropsShort() {
  const isDarkMode = useIsDarkMode();
  return isInterface ? {
    "$platform-web": {
      boxShadow: isDarkMode ? `0px 1px 3px 0px ${opacify(12, colors.black)}, 0px 1px 2px 0px ${opacify(24, colors.black)}` : `0px 1px 6px 2px ${opacify(3, colors.black)}, 0px 1px 2px 0px ${opacify(2, colors.black)}`
    }
  } : {
    shadowColor: isDarkMode ? "rgba(0,\xA00,\xA00,\xA00.24)" : "rgba(0,\xA00,\xA00,\xA00.02)",
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 6
  };
}
export function useShadowPropsMedium() {
  const isDarkMode = useIsDarkMode();
  return isInterface ? {
    "$platform-web": {
      boxShadow: isDarkMode ? `0px 10px 15px -3px ${opacify(54, colorsDark.surface1)}, 0px 4px 6px -2px ${opacify(40, colorsDark.surface1)}` : `0px 6px 12px -3px ${opacify(4, colorsDark.surface1)}, 0px 2px 5px -2px ${opacify(3, colorsDark.surface1)}`
    }
  } : {
    shadowColor: opacify(4, colorsDark.surface1),
    shadowOffset: { width: 0, height: 6 },
    shadowRadius: 12
  };
}
