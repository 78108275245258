import { Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Sign, AnimatedSign] = createIcon({
  name: "Sign",
  getIcon: (props) => <Svg viewBox="0 0 10 10" fill="none" {...props}><Path
    d="M6.62967 9.1964C6.45767 9.2784 6.2567 9.33942 6.0292 9.38042L2.74417 9.95543C1.64917 10.1504 1.00411 9.70043 0.814114 8.60542L0.0442166 4.23042C-0.150783 3.13542 0.299192 2.49043 1.39419 2.29543L2.29312 2.13741C2.39562 2.11941 2.4852 2.20892 2.4667 2.31142L1.87869 5.64043C1.61369 7.15543 2.32865 8.17542 3.83865 8.44042C3.83865 8.44042 6.44817 8.89891 6.58267 8.92491C6.74867 8.95491 6.77517 9.1319 6.62967 9.1964ZM9.9562 2.55342L9.18411 6.93041C8.99811 7.98441 8.3902 8.43491 7.3677 8.29491C7.3287 8.28941 7.2937 8.28943 7.2532 8.28243L3.97061 7.70342C2.87611 7.51042 2.42568 6.86692 2.61868 5.77242L3.29019 1.9644L3.39065 1.3954C3.58365 0.300902 4.22718 -0.149587 5.32168 0.0434127L8.60464 0.622423C9.69864 0.815423 10.1492 1.45942 9.9562 2.55342ZM6.77664 5.94841C6.81264 5.74441 6.67668 5.5499 6.47268 5.5139L4.42068 5.1519C4.21768 5.1154 4.02222 5.25242 3.98672 5.45592C3.95072 5.65992 4.08668 5.85443 4.29068 5.89043L6.34268 6.25243C6.36468 6.25643 6.38661 6.25792 6.40811 6.25792C6.58661 6.25842 6.74464 6.13041 6.77664 5.94841ZM8.29812 4.5179C8.33412 4.3139 8.19817 4.11942 7.99417 4.08342L4.7117 3.50441C4.5097 3.46891 4.31312 3.60493 4.27762 3.80843C4.24162 4.01243 4.37769 4.20691 4.58169 4.24291L7.86416 4.82192C7.88616 4.82592 7.90821 4.82741 7.92971 4.82741C8.10821 4.82741 8.26612 4.6994 8.29812 4.5179ZM8.64822 2.88692C8.68422 2.68292 8.54814 2.48841 8.34414 2.45241L5.06167 1.8734C4.85967 1.8374 4.66321 1.97392 4.62771 2.17742C4.59171 2.38142 4.72767 2.57592 4.93167 2.61192L8.21414 3.1909C8.23614 3.1949 8.25819 3.19643 8.27969 3.19643C8.45819 3.19693 8.61622 3.06892 8.64822 2.88692Z"
    fill={"currentColor"}
  /></Svg>,
  defaultFill: "#F5F6FC"
});
