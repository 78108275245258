"use strict";
import { ButtonEmphasis, ButtonSize, ThemeButton } from "components/Button/buttons";
import { GetHelpHeader } from "components/Modal/GetHelpHeader";
import { ColumnCenter } from "components/deprecated/Column";
import Row from "components/deprecated/Row";
import styled from "lib/styled-components";
import { ThemedText } from "theme/components";
import { Modal } from "uniswap/src/components/modals/Modal";
import { ModalName } from "uniswap/src/features/telemetry/constants";
export const Container = styled(ColumnCenter)`
  background-color: ${({ theme }) => theme.surface1};
  border-radius: 16px;
  padding: 16px 24px 24px 24px;
  width: 100%;
`;
const IconContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.surface3};
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
`;
const TitleText = styled(ThemedText.HeadlineMedium)`
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  font-weight: 500;
`;
const DescriptionText = styled(ThemedText.BodySecondary)`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
`;
const StyledButton = styled(ThemeButton)`
  display: flex;
  flex-grow: 1;
  height: 40px;
  ${({ $color, theme }) => $color && `color: ${theme[$color]};`}
  border-radius: 12px;
`;
const DialogHeader = styled(GetHelpHeader)`
  padding: 4px 0px;
`;
export var DialogButtonType = /* @__PURE__ */ ((DialogButtonType2) => {
  DialogButtonType2["Primary"] = "primary";
  DialogButtonType2["Error"] = "error";
  DialogButtonType2["Accent"] = "accent";
  return DialogButtonType2;
})(DialogButtonType || {});
function getButtonEmphasis(type) {
  switch (type) {
    case "error" /* Error */:
      return ButtonEmphasis.destructive;
    case "accent" /* Accent */:
      return ButtonEmphasis.high;
    default:
      return ButtonEmphasis.medium;
  }
}
export function DialogContent({ icon, title, description, body, buttonsConfig }) {
  const { left, right, gap } = buttonsConfig ?? {};
  return <ColumnCenter gap="lg"><ColumnCenter gap="16px"><IconContainer>{icon}</IconContainer><ColumnCenter gap="sm"><TitleText>{title}</TitleText><DescriptionText>{description}</DescriptionText>{body}</ColumnCenter></ColumnCenter><Row align="center" justify="center" gap={gap ?? "md"}>{left ? "title" in left ? <StyledButton
    size={ButtonSize.small}
    onClick={left.onClick}
    disabled={left.disabled}
    emphasis={getButtonEmphasis(left.type)}
    $color={left.textColor}
  >{left.title}</StyledButton> : left : null}{right ? "title" in right ? <StyledButton
    size={ButtonSize.small}
    onClick={right.onClick}
    disabled={right.disabled}
    emphasis={getButtonEmphasis(right.type)}
    $color={right.textColor}
  >{right.title}</StyledButton> : right : null}</Row></ColumnCenter>;
}
export function Dialog(props) {
  return <Modal name={ModalName.Dialog} isModalOpen={props.isVisible} onClose={props.onCancel} padding={0}><Container gap="lg"><DialogHeader closeModal={props.onCancel} closeDataTestId="Dialog-closeButton" /><DialogContent {...props} /></Container></Modal>;
}
