import { Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [DocumentList, AnimatedDocumentList] = createIcon({
  name: "DocumentList",
  getIcon: (props) => <Svg viewBox="0 0 29 28" fill="none" {...props}><Path
    d="M25 13.4167V21.2917C25 22.0967 24.3467 22.75 23.5417 22.75C22.7367 22.75 22.0833 22.0967 22.0833 21.2917V11.6667H23.25C24.216 11.6667 25 12.4507 25 13.4167ZM20.3333 22.1667V7C20.3333 4.66667 19.1667 3.5 16.8333 3.5H7.5C5.16667 3.5 4 4.66667 4 7V21C4 23.3333 5.16667 24.5 7.5 24.5H22.6667C21.3787 24.5 20.3333 23.4558 20.3333 22.1667ZM8.08333 19.5417C7.60033 19.5417 7.20833 19.1497 7.20833 18.6667C7.20833 18.1837 7.60033 17.7917 8.08333 17.7917C8.56633 17.7917 8.95833 18.1837 8.95833 18.6667C8.95833 19.1497 8.56633 19.5417 8.08333 19.5417ZM8.08333 14.875C7.60033 14.875 7.20833 14.483 7.20833 14C7.20833 13.517 7.60033 13.125 8.08333 13.125C8.56633 13.125 8.95833 13.517 8.95833 14C8.95833 14.483 8.56633 14.875 8.08333 14.875ZM8.08333 10.2083C7.60033 10.2083 7.20833 9.81633 7.20833 9.33333C7.20833 8.85033 7.60033 8.45833 8.08333 8.45833C8.56633 8.45833 8.95833 8.85033 8.95833 9.33333C8.95833 9.81633 8.56633 10.2083 8.08333 10.2083ZM16.25 19.5417H11C10.517 19.5417 10.125 19.1497 10.125 18.6667C10.125 18.1837 10.517 17.7917 11 17.7917H16.25C16.733 17.7917 17.125 18.1837 17.125 18.6667C17.125 19.1497 16.733 19.5417 16.25 19.5417ZM16.25 14.875H11C10.517 14.875 10.125 14.483 10.125 14C10.125 13.517 10.517 13.125 11 13.125H16.25C16.733 13.125 17.125 13.517 17.125 14C17.125 14.483 16.733 14.875 16.25 14.875ZM16.25 10.2083H11C10.517 10.2083 10.125 9.81633 10.125 9.33333C10.125 8.85033 10.517 8.45833 11 8.45833H16.25C16.733 8.45833 17.125 8.85033 17.125 9.33333C17.125 9.81633 16.733 10.2083 16.25 10.2083Z"
    fill={"currentColor"}
  /></Svg>,
  defaultFill: "#7D7D7D"
});
