"use strict";
import Loader from "components/Icons/LoadingSpinner";
import { routes, useRouterConfig } from "pages/RouteDefinitions";
import { Suspense, lazy, memo } from "react";
import { Route, Routes } from "react-router-dom";
const AppChrome = lazy(() => import(
  /* webpackPreload: true */
  "./Chrome"
));
export const Body = memo(function Body2() {
  const routerConfig = useRouterConfig();
  return <><Suspense><AppChrome /></Suspense><Suspense fallback={<Loader />}><Routes>{routes.map(
    (route) => route.enabled(routerConfig) ? <Route key={route.path} path={route.path} element={route.getElement(routerConfig)}>{route.nestedPaths.map((nestedPath) => <Route
      path={nestedPath}
      element={route.getElement(routerConfig)}
      key={`${route.path}/${nestedPath}`}
    />)}</Route> : null
  )}</Routes></Suspense></>;
});
