// Follow up to remove: WEB-5827
// @generated by protoc-gen-connect-query v1.4.1 with parameter "target=ts"
// @generated from file conversionproxy/v1/api.proto (package conversionproxy.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from '@bufbuild/protobuf'
import { ProxyRequest, ProxyResponse } from './api_pb.ts'

/**
 * @generated from rpc conversionproxy.v1.ConversionProxyService.Proxy
 */
export const proxy = {
  localName: 'proxy',
  name: 'Proxy',
  kind: MethodKind.Unary,
  I: ProxyRequest,
  O: ProxyResponse,
  service: {
    typeName: 'conversionproxy.v1.ConversionProxyService',
  },
} as const
