"use strict";
import { useAccount } from "hooks/useAccount";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { useMemo } from "react";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { useSupportedChainId } from "uniswap/src/features/chains/hooks/useSupportedChainId";
export function useStablecoinAmountFromFiatValue(fiatValue) {
  const { chainId } = useAccount();
  const supportedChainId = useSupportedChainId(chainId);
  const stablecoin = supportedChainId ? getChainInfo(supportedChainId).spotPriceStablecoinAmount.currency : void 0;
  return useMemo(() => {
    if (fiatValue === null || fiatValue === void 0 || !chainId || !stablecoin) {
      return void 0;
    }
    const parsedForDecimals = fiatValue.toFixed(stablecoin.decimals).toString();
    try {
      return tryParseCurrencyAmount(parsedForDecimals, stablecoin);
    } catch (error) {
      return void 0;
    }
  }, [chainId, fiatValue, stablecoin]);
}
