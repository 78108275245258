import { Plus as PlusIcon } from "ui/src/components/icons";
import { Flex } from "ui/src/components/layout/Flex";
import { TouchableArea } from "ui/src/components/touchable/TouchableArea";
import { iconSizes } from "ui/src/theme";
export var PlusMinusButtonType = /* @__PURE__ */ ((PlusMinusButtonType2) => {
  PlusMinusButtonType2[PlusMinusButtonType2["Plus"] = 0] = "Plus";
  PlusMinusButtonType2[PlusMinusButtonType2["Minus"] = 1] = "Minus";
  return PlusMinusButtonType2;
})(PlusMinusButtonType || {});
export function PlusMinusButton({
  type,
  disabled,
  onPress
}) {
  return <TouchableArea
    alignItems="center"
    backgroundColor={disabled ? "$surface3" : "$neutral2"}
    borderRadius="$roundedFull"
    disabled={disabled}
    height={iconSizes.icon28}
    justifyContent="center"
    width={iconSizes.icon28}
    onPress={() => onPress(type)}
  >{type === 0 /* Plus */ ? <PlusIcon color="$surface1" size="$icon.12" strokeWidth={2.5} /> : <Flex backgroundColor="$surface1" borderRadius="$rounded12" height={2} width={10} />}</TouchableArea>;
}
