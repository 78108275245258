"use strict";
import { exploreSearchStringAtom } from "components/Tokens/state";
import {
  PoolSortFields,
  V2_BIPS,
  calculate1DVolOverTvl,
  calculateApr
} from "graphql/data/pools/useTopPools";
import { OrderDirection } from "graphql/data/util";
import { useAtomValue } from "jotai/utils";
import { useContext, useMemo } from "react";
import { ExploreContext, giveExploreStatDefaultValue } from "state/explore";
import { FeatureFlags } from "uniswap/src/features/gating/flags";
import { useFeatureFlag } from "uniswap/src/features/gating/hooks";
function useFilteredPools(pools) {
  const filterString = useAtomValue(exploreSearchStringAtom);
  const isV4Enabled = useFeatureFlag(FeatureFlags.V4Data);
  const lowercaseFilterString = useMemo(() => filterString.toLowerCase(), [filterString]);
  return useMemo(
    () => pools?.filter((pool) => {
      const addressIncludesFilterString = pool.id.toLowerCase().includes(lowercaseFilterString);
      const token0IncludesFilterString = pool.token0?.symbol?.toLowerCase().includes(lowercaseFilterString);
      const token1IncludesFilterString = pool.token1?.symbol?.toLowerCase().includes(lowercaseFilterString);
      const token0HashIncludesFilterString = pool.token0?.address?.toLowerCase().includes(lowercaseFilterString);
      const token1HashIncludesFilterString = pool.token1?.address?.toLowerCase().includes(lowercaseFilterString);
      const poolName = `${pool.token0?.symbol}/${pool.token1?.symbol}`.toLowerCase();
      const poolNameIncludesFilterString = poolName.includes(lowercaseFilterString);
      return (token0IncludesFilterString || token1IncludesFilterString || addressIncludesFilterString || token0HashIncludesFilterString || token1HashIncludesFilterString || poolNameIncludesFilterString) && (pool.protocolVersion?.toLowerCase() !== "v4" || isV4Enabled);
    }),
    [isV4Enabled, lowercaseFilterString, pools]
  );
}
function sortPools(sortState, pools) {
  return pools?.sort((a, b) => {
    switch (sortState.sortBy) {
      case PoolSortFields.VolOverTvl:
        return sortState.sortDirection === OrderDirection.Desc ? (b?.volOverTvl ?? 0) - (a?.volOverTvl ?? 0) : (a?.volOverTvl ?? 0) - (b?.volOverTvl ?? 0);
      case PoolSortFields.Volume24h:
        return sortState.sortDirection === OrderDirection.Desc ? giveExploreStatDefaultValue(b?.volume1Day?.value) - giveExploreStatDefaultValue(a?.volume1Day?.value) : giveExploreStatDefaultValue(a?.volume1Day?.value) - giveExploreStatDefaultValue(b?.volume1Day?.value);
      case PoolSortFields.Volume30D:
        return sortState.sortDirection === OrderDirection.Desc ? giveExploreStatDefaultValue(b.volume30Day?.value) - giveExploreStatDefaultValue(a.volume30Day?.value) : giveExploreStatDefaultValue(a.volume30Day?.value) - giveExploreStatDefaultValue(b.volume30Day?.value);
      case PoolSortFields.Apr:
        return sortState.sortDirection === OrderDirection.Desc ? b.apr.greaterThan(a.apr) ? 1 : -1 : a.apr.greaterThan(b.apr) ? 1 : -1;
      default:
        return sortState.sortDirection === OrderDirection.Desc ? giveExploreStatDefaultValue(b?.totalLiquidity?.value) - giveExploreStatDefaultValue(a?.totalLiquidity?.value) : giveExploreStatDefaultValue(a?.totalLiquidity?.value) - giveExploreStatDefaultValue(b?.totalLiquidity?.value);
    }
  });
}
function convertPoolStatsToPoolStat(poolStats) {
  return {
    ...poolStats,
    apr: calculateApr(
      giveExploreStatDefaultValue(poolStats.volume1Day?.value),
      giveExploreStatDefaultValue(poolStats.totalLiquidity?.value),
      poolStats.feeTier ?? V2_BIPS
    ),
    feeTier: poolStats.feeTier ?? V2_BIPS,
    volOverTvl: calculate1DVolOverTvl(poolStats.volume1Day?.value, poolStats.totalLiquidity?.value),
    hookAddress: poolStats.hook?.address
  };
}
export function useExploreContextTopPools(sortState) {
  const {
    exploreStats: { data, isLoading, error: isError }
  } = useContext(ExploreContext);
  return useTopPools({ data, isLoading, isError }, sortState);
}
export function useTopPools(topPoolData, sortState) {
  const { data, isLoading, isError } = topPoolData;
  const sortedPoolStats = useMemo(() => {
    const poolStats = data?.stats?.poolStats?.map((poolStat) => convertPoolStatsToPoolStat(poolStat));
    return sortPools(sortState, poolStats);
  }, [data?.stats?.poolStats, sortState]);
  const filteredPoolStats = useFilteredPools(sortedPoolStats);
  return { topPools: filteredPoolStats, isLoading, isError };
}
