"use strict";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDerivedSendInfo } from "state/send/hooks";
import { useSwapAndLimitContext } from "state/swap/useSwapContext";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
const DEFAULT_SEND_STATE = {
  exactAmountToken: void 0,
  exactAmountFiat: "",
  recipient: "",
  inputCurrency: void 0,
  inputInFiat: true,
  validatedRecipientData: void 0
};
const DEFAULT_TESTNET_SEND_STATE = {
  exactAmountToken: "",
  exactAmountFiat: void 0,
  recipient: "",
  inputCurrency: void 0,
  inputInFiat: false,
  validatedRecipientData: void 0
};
export const SendContext = createContext({
  sendState: DEFAULT_SEND_STATE,
  setSendState: () => void 0,
  derivedSendInfo: {
    currencyBalance: void 0,
    parsedTokenAmount: void 0,
    exactAmountOut: void 0,
    recipientData: void 0,
    transaction: void 0,
    gasFeeCurrencyAmount: void 0,
    gasFee: void 0,
    inputError: void 0
  }
});
export function useSendContext() {
  return useContext(SendContext);
}
export function SendContextProvider({ children }) {
  const {
    currencyState: { inputCurrency, outputCurrency },
    setCurrencyState
  } = useSwapAndLimitContext();
  const { isTestnetModeEnabled } = useEnabledChains();
  const initialCurrency = useMemo(() => {
    return inputCurrency ?? outputCurrency;
  }, [inputCurrency, outputCurrency]);
  const [sendState, setSendState] = useState({
    ...isTestnetModeEnabled ? DEFAULT_TESTNET_SEND_STATE : DEFAULT_SEND_STATE,
    inputCurrency: initialCurrency
  });
  const derivedSendInfo = useDerivedSendInfo(sendState);
  useEffect(() => {
    setSendState((prev) => ({ ...prev, inputCurrency: initialCurrency }));
  }, [initialCurrency]);
  useEffect(() => {
    setCurrencyState((prev) => {
      if (prev.inputCurrency?.chainId !== sendState.inputCurrency?.chainId) {
        return { inputCurrency: sendState.inputCurrency };
      } else if (outputCurrency && sendState.inputCurrency?.equals(outputCurrency)) {
        return { ...prev, outputCurrency: void 0, inputCurrency: sendState.inputCurrency };
      } else {
        return { ...prev, inputCurrency: sendState.inputCurrency };
      }
    });
  }, [outputCurrency, sendState, setCurrencyState]);
  const value = useMemo(
    () => ({
      sendState,
      setSendState,
      derivedSendInfo
    }),
    [derivedSendInfo, setSendState, sendState]
  );
  return <SendContext.Provider value={value}>{children}</SendContext.Provider>;
}
