"use strict";
import { InterfaceElementName, SwapEventName } from "@uniswap/analytics-events";
import { LoadingOpacityContainer } from "components/Loader/styled";
import Column from "components/deprecated/Column";
import { RowBetween, RowFixed } from "components/deprecated/Row";
import GasEstimateTooltip from "components/swap/GasEstimateTooltip";
import SwapLineItem, { SwapLineItemType } from "components/swap/SwapLineItem";
import TradePrice from "components/swap/TradePrice";
import styled, { useTheme } from "lib/styled-components";
import { formatCommonPropertiesForTrade } from "lib/utils/analytics";
import { useState } from "react";
import { ChevronDown } from "react-feather";
import { isSubmittableTrade } from "state/routing/utils";
import { ThemedText } from "theme/components";
import { HeightAnimator } from "ui/src";
import Trace from "uniswap/src/features/telemetry/Trace";
import { Trans } from "uniswap/src/i18n";
import { useTrace } from "utilities/src/telemetry/trace/TraceContext";
import { useFormatter } from "utils/formatNumbers";
const StyledHeaderRow = styled(RowBetween)`
  padding: 0;
  align-items: center;
  cursor: ${({ disabled }) => disabled ? "initial" : "pointer"};
`;
const RotatingArrow = styled(ChevronDown)`
  transform: ${({ open }) => open ? "rotate(180deg)" : "none"};
  transition: transform 0.1s linear;
`;
const SwapDetailsWrapper = styled(Column)`
  padding-top: ${({ theme }) => theme.grids.md};
`;
const Wrapper = styled(Column)`
  border-radius: 16px;
  padding: 12px 16px;
`;
export default function SwapDetailsDropdown(props) {
  const { trade, syncing, loading, allowedSlippage } = props;
  const theme = useTheme();
  const [showDetails, setShowDetails] = useState(false);
  const trace = useTrace();
  return <Wrapper><Trace
    logPress
    logImpression={!showDetails}
    eventOnTrigger={SwapEventName.SWAP_DETAILS_EXPANDED}
    element={InterfaceElementName.SWAP_DETAILS_DROPDOWN}
    properties={{
      ...trade ? formatCommonPropertiesForTrade(trade, allowedSlippage) : {},
      ...trace
    }}
  ><StyledHeaderRow
    data-testid="swap-details-header-row"
    onClick={() => setShowDetails(!showDetails)}
    disabled={!trade}
    open={showDetails}
  ><RowFixed>{trade ? <LoadingOpacityContainer $loading={syncing} data-testid="trade-price-container"><TradePrice price={trade.executionPrice} /></LoadingOpacityContainer> : loading || syncing ? <ThemedText.DeprecatedMain fontSize={14}><Trans i18nKey="swap.fetchingBestPrice" /></ThemedText.DeprecatedMain> : null}</RowFixed><RowFixed gap="xs">{!showDetails && isSubmittableTrade(trade) && <GasEstimateTooltip trade={trade} loading={syncing || loading} />}<RotatingArrow stroke={trade ? theme.neutral3 : theme.surface2} open={Boolean(trade && showDetails)} /></RowFixed></StyledHeaderRow></Trace><AdvancedSwapDetails {...props} open={showDetails} /></Wrapper>;
}
function AdvancedSwapDetails(props) {
  const { open, trade, allowedSlippage, syncing = false, priceImpact } = props;
  const format = useFormatter();
  if (!trade) {
    return null;
  }
  const lineItemProps = { trade, allowedSlippage, format, syncing, priceImpact };
  return <HeightAnimator open={open}><SwapDetailsWrapper gap="sm" data-testid="advanced-swap-details"><SwapLineItem {...lineItemProps} type={SwapLineItemType.PRICE_IMPACT} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.MAX_SLIPPAGE} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.MINIMUM_OUTPUT} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.INPUT_TOKEN_FEE_ON_TRANSFER} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.OUTPUT_TOKEN_FEE_ON_TRANSFER} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.SWAP_FEE} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.NETWORK_COST} /><SwapLineItem {...lineItemProps} type={SwapLineItemType.ROUTING_INFO} /></SwapDetailsWrapper></HeightAnimator>;
}
