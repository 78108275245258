"use strict";
import { LoadingRow } from "components/Loader/styled";
import { MouseoverTooltip } from "components/Tooltip";
import { useIsMobile } from "hooks/screenSize/useIsMobile";
import useHoverProps from "hooks/useHoverProps";
import styled from "lib/styled-components";
import { ThemedText } from "theme/components";
import { Flex } from "ui/src";
const LabelText = styled(ThemedText.BodySmall)`
  cursor: ${({ hasTooltip }) => hasTooltip ? "help" : "auto"};
  color: ${({ theme }) => theme.neutral2};
`;
const DetailRowValue = styled(ThemedText.BodySmall)`
  text-align: right;
  overflow-wrap: break-word;
`;
function ValueWrapper({ children, lineItem, labelHovered, syncing }) {
  const { TooltipBody, tooltipSize, loaderWidth } = lineItem;
  const isMobile = useIsMobile();
  if (syncing) {
    return <LoadingRow data-testid="loading-row" height={15} width={loaderWidth ?? 50} />;
  }
  if (!TooltipBody) {
    return <DetailRowValue>{children}</DetailRowValue>;
  }
  return <MouseoverTooltip
    placement={isMobile ? "auto" : "right"}
    forceShow={labelHovered}
    size={tooltipSize}
    text={<ThemedText.Caption color="neutral2"><TooltipBody /></ThemedText.Caption>}
  ><DetailRowValue>{children}</DetailRowValue></MouseoverTooltip>;
}
export function DetailLineItem({ LineItem, syncing }) {
  const [labelHovered, hoverProps] = useHoverProps();
  return <Flex row alignItems="center" justifyContent="space-between" width="100%"><LabelText {...hoverProps} hasTooltip={!!LineItem.TooltipBody} data-testid="swap-li-label"><LineItem.Label /></LabelText><ValueWrapper lineItem={LineItem} labelHovered={labelHovered} syncing={syncing ?? false}><LineItem.Value /></ValueWrapper></Flex>;
}
