"use strict";
import { Percent } from "@uniswap/sdk-core";
import { Position as V3Position } from "@uniswap/v3-sdk";
import RangeBadge from "components/Badge/RangeBadge";
import HoverInlineText from "components/HoverInlineText";
import Loader from "components/Icons/LoadingSpinner";
import { DoubleCurrencyLogo } from "components/Logo/DoubleLogo";
import { RowBetween } from "components/deprecated/Row";
import { useToken } from "hooks/Tokens";
import useIsTickAtLimit from "hooks/useIsTickAtLimit";
import { usePool } from "hooks/usePools";
import styled from "lib/styled-components";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Bound } from "state/mint/v3/actions";
import { MEDIA_WIDTHS } from "theme";
import { HideSmall, SmallOnly, ThemedText } from "theme/components";
import { DAI, USDC_MAINNET, USDT, WBTC, WRAPPED_NATIVE_CURRENCY } from "uniswap/src/constants/tokens";
import { Trans } from "uniswap/src/i18n";
import { useFormatter } from "utils/formatNumbers";
import { unwrappedToken } from "utils/unwrappedToken";
const LinkRow = styled(Link)`
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme }) => theme.neutral1};
  padding: 16px;
  text-decoration: none;
  font-weight: 535;

  & > div:not(:first-child) {
    text-align: center;
  }
  :hover {
    background-color: ${({ theme }) => theme.deprecated_hoverDefault};
  }

  @media screen and (min-width: ${MEDIA_WIDTHS.deprecated_upToSmall}px) {
    /* flex-direction: row; */
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    flex-direction: column;
    row-gap: 8px;
  `};
`;
const DataLineItem = styled.div`
  font-size: 14px;
`;
const RangeLineItem = styled(DataLineItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  width: 100%;
`;
const DoubleArrow = styled.span`
  font-size: 12px;
  margin: 0 2px;
  color: ${({ theme }) => theme.neutral1};
`;
const RangeText = styled(ThemedText.BodySmall)`
  font-size: 14px !important;
  word-break: break-word;
  padding: 0.25rem 0.25rem;
  border-radius: 8px;
`;
const FeeTierText = styled(ThemedText.BodyPrimary)`
  margin-left: 8px !important;
  line-height: 12px;
  color: ${({ theme }) => theme.neutral3};
`;
const ExtentsText = styled(ThemedText.BodySmall)`
  color: ${({ theme }) => theme.neutral2};
  display: inline-block;
  line-height: 16px;
  margin-right: 4px !important;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};
`;
const PrimaryPositionIdData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin-right: 8px;
  }
`;
export function getPriceOrderingFromPositionForUI(position) {
  if (!position) {
    return {};
  }
  const token0 = position.amount0.currency;
  const token1 = position.amount1.currency;
  const stables = [DAI, USDC_MAINNET, USDT];
  if (stables.some((stable) => stable.equals(token0))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1
    };
  }
  const bases = [...Object.values(WRAPPED_NATIVE_CURRENCY), WBTC];
  if (bases.some((base) => base && base.equals(token1))) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1
    };
  }
  if (position.token0PriceUpper.lessThan(1)) {
    return {
      priceLower: position.token0PriceUpper.invert(),
      priceUpper: position.token0PriceLower.invert(),
      quote: token0,
      base: token1
    };
  }
  return {
    priceLower: position.token0PriceLower,
    priceUpper: position.token0PriceUpper,
    quote: token1,
    base: token0
  };
}
export default function PositionListItem({
  token0: token0Address,
  token1: token1Address,
  tokenId,
  fee: feeAmount,
  liquidity,
  tickLower,
  tickUpper
}) {
  const { formatDelta, formatTickPrice } = useFormatter();
  const token0 = useToken(token0Address);
  const token1 = useToken(token1Address);
  const currency0 = token0 ? unwrappedToken(token0) : void 0;
  const currency1 = token1 ? unwrappedToken(token1) : void 0;
  const [, pool] = usePool(currency0 ?? void 0, currency1 ?? void 0, feeAmount);
  const position = useMemo(() => {
    if (pool) {
      return new V3Position({ pool, liquidity: liquidity.toString(), tickLower, tickUpper });
    }
    return void 0;
  }, [liquidity, pool, tickLower, tickUpper]);
  const tickAtLimit = useIsTickAtLimit(feeAmount, tickLower, tickUpper);
  const { priceLower, priceUpper, quote, base } = getPriceOrderingFromPositionForUI(position);
  const currencyQuote = quote && unwrappedToken(quote);
  const currencyBase = base && unwrappedToken(base);
  const outOfRange = pool ? pool.tickCurrent < tickLower || pool.tickCurrent >= tickUpper : false;
  const positionSummaryLink = "/pools/" + tokenId;
  const removed = liquidity?.eq(0);
  return <LinkRow to={positionSummaryLink}><RowBetween><PrimaryPositionIdData><DoubleCurrencyLogo currencies={[currencyBase, currencyQuote]} size={18} /><ThemedText.SubHeader>
            &nbsp;{currencyQuote?.symbol}&nbsp;/&nbsp;{currencyBase?.symbol}</ThemedText.SubHeader><FeeTierText>{formatDelta(parseFloat(new Percent(feeAmount, 1e6).toSignificant()))}</FeeTierText></PrimaryPositionIdData><RangeBadge removed={removed} inRange={!outOfRange} /></RowBetween>{priceLower && priceUpper ? <RangeLineItem><RangeText><ExtentsText><Trans i18nKey="pool.min.label" />
              &nbsp;
            </ExtentsText><Trans
    i18nKey="liquidityPool.positions.price.formatted"
    components={{
      amountWithSymbol: <><span>{formatTickPrice({
        price: priceLower,
        atLimit: tickAtLimit,
        direction: Bound.LOWER
      })}{" "}</span><HoverInlineText text={currencyQuote?.symbol} /></>,
      outputToken: <HoverInlineText text={currencyBase?.symbol ?? ""} />
    }}
  /></RangeText>{" "}<HideSmall><DoubleArrow>↔</DoubleArrow>{" "}</HideSmall><SmallOnly><DoubleArrow>↔</DoubleArrow>{" "}</SmallOnly><RangeText><ExtentsText><Trans i18nKey="pool.max.label" /></ExtentsText><Trans
    i18nKey="liquidityPool.positions.price.formatted"
    components={{
      amountWithSymbol: <><span>{formatTickPrice({
        price: priceUpper,
        atLimit: tickAtLimit,
        direction: Bound.UPPER
      })}{" "}</span><HoverInlineText text={currencyQuote?.symbol} /></>,
      outputToken: <HoverInlineText maxCharacters={10} text={currencyBase?.symbol} />
    }}
  /></RangeText></RangeLineItem> : <Loader />}</LinkRow>;
}
