"use strict";
import { useUpdateAtom } from "jotai/utils";
import { multicallUpdaterSwapChainIdAtom } from "lib/hooks/useBlockNumber";
import { useCallback, useEffect, useMemo, useState } from "react";
import { MultichainContext } from "state/multichain/types";
export function MultichainContextProvider({
  children,
  initialChainId
}) {
  const [selectedChainId, setSelectedChainId] = useState(initialChainId);
  const [isUserSelectedToken, setIsUserSelectedToken] = useState(false);
  const setMulticallUpdaterChainId = useUpdateAtom(multicallUpdaterSwapChainIdAtom);
  useEffect(() => {
    const chainId = selectedChainId ?? void 0;
    setMulticallUpdaterChainId(chainId);
  }, [selectedChainId, setMulticallUpdaterChainId]);
  const reset = useCallback(() => {
    setSelectedChainId(initialChainId);
    setIsUserSelectedToken(false);
  }, [initialChainId]);
  const value = useMemo(() => {
    return {
      reset,
      setSelectedChainId,
      initialChainId,
      chainId: selectedChainId ?? void 0,
      isMultichainContext: true,
      isUserSelectedToken,
      setIsUserSelectedToken
    };
  }, [initialChainId, selectedChainId, isUserSelectedToken, reset]);
  return <MultichainContext.Provider value={value}>{children}</MultichainContext.Provider>;
}
