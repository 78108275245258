"use strict";
import { QueryClient } from "@tanstack/react-query";
import { injectedWithFallback } from "components/Web3Provider/injectedWithFallback";
import { WC_PARAMS } from "components/Web3Provider/walletConnect";
import { UNISWAP_LOGO } from "ui/src/assets";
import { UNISWAP_WEB_URL } from "uniswap/src/constants/urls";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { ALL_CHAIN_IDS, UniverseChainId } from "uniswap/src/features/chains/types";
import { createClient } from "viem";
import { createConfig, http } from "wagmi";
import { connect } from "wagmi/actions";
import { coinbaseWallet, injected, safe, walletConnect } from "wagmi/connectors";
export const wagmiConfig = createConfig({
  chains: [getChainInfo(UniverseChainId.Mainnet), ...ALL_CHAIN_IDS.map(getChainInfo)],
  connectors: [
    injectedWithFallback(),
    walletConnect(WC_PARAMS),
    coinbaseWallet({
      appName: "Uniswap",
      // CB SDK doesn't pass the parent origin context to their passkey site
      // Flagged to CB team and can remove UNISWAP_WEB_URL once fixed
      appLogoUrl: `${UNISWAP_WEB_URL}${UNISWAP_LOGO}`,
      reloadOnDisconnect: false,
      enableMobileWalletLink: true
    }),
    safe()
  ],
  client({ chain }) {
    return createClient({
      chain,
      batch: { multicall: true },
      pollingInterval: 12e3,
      transport: http(chain.rpcUrls.interface.http[0])
    });
  }
});
export const queryClient = new QueryClient();
if (window.Cypress?.eagerlyConnect) {
  connect(wagmiConfig, { connector: injected() });
}
