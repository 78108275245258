"use strict";
import * as Card from "nft/components/card/containers";
import { MarketplaceContainer } from "nft/components/card/icons";
import { MediaContainer } from "nft/components/card/media";
import { detailsHref, getNftDisplayComponent, useSelectAsset } from "nft/components/card/utils";
import { useBag } from "nft/hooks";
import { UniformAspectRatios } from "nft/types";
import { NumberType, useFormatter } from "utils/formatNumbers";
export const NftCard = ({
  asset,
  display,
  isSelected,
  selectAsset,
  unselectAsset,
  isDisabled,
  onButtonClick,
  onCardClick,
  sendAnalyticsEvent,
  mediaShouldBePlaying,
  uniformAspectRatio = UniformAspectRatios.square,
  setUniformAspectRatio,
  renderedHeight,
  setRenderedHeight,
  setCurrentTokenPlayingMedia,
  testId,
  hideDetails = false
}) => {
  const clickActionButton = useSelectAsset({
    selectAsset,
    unselectAsset,
    isSelected,
    isDisabled,
    onClick: onButtonClick
  });
  const { bagExpanded, setBagExpanded } = useBag((state) => ({
    bagExpanded: state.bagExpanded,
    setBagExpanded: state.setBagExpanded
  }));
  const { formatNumberOrString } = useFormatter();
  const collectionNft = "marketplace" in asset;
  const profileNft = "asset_contract" in asset;
  const tokenType = collectionNft ? asset.tokenType : profileNft ? asset.asset_contract.tokenType : void 0;
  const marketplace = collectionNft ? asset.marketplace : void 0;
  const listedPrice = profileNft && !isDisabled && asset.floor_sell_order_price ? formatNumberOrString({ input: asset.floor_sell_order_price, type: NumberType.NFTTokenFloorPrice }) : void 0;
  return <Card.Container
    isSelected={isSelected}
    isDisabled={isDisabled}
    detailsHref={onCardClick ? void 0 : detailsHref(asset)}
    testId={testId}
    onClick={() => {
      if (bagExpanded) {
        setBagExpanded({ bagExpanded: false });
      }
      onCardClick?.();
      sendAnalyticsEvent?.();
    }}
  ><MediaContainer isDisabled={isDisabled}><MarketplaceContainer
    hidePrice={hideDetails}
    isSelected={isSelected}
    marketplace={marketplace}
    tokenType={tokenType}
    listedPrice={listedPrice}
  />{getNftDisplayComponent(
    asset,
    mediaShouldBePlaying,
    setCurrentTokenPlayingMedia,
    uniformAspectRatio,
    setUniformAspectRatio,
    renderedHeight,
    setRenderedHeight
  )}</MediaContainer>{!hideDetails && <><Card.DetailsRelativeContainer><Card.DetailsContainer><Card.InfoContainer><Card.PrimaryRow><Card.PrimaryDetails><Card.PrimaryInfo>{display.primaryInfo}</Card.PrimaryInfo>{display.primaryInfoIcon}</Card.PrimaryDetails>{display.primaryInfoRight}</Card.PrimaryRow><Card.SecondaryRow><Card.SecondaryDetails><Card.SecondaryInfo>{display.secondaryInfo}</Card.SecondaryInfo></Card.SecondaryDetails></Card.SecondaryRow></Card.InfoContainer></Card.DetailsContainer></Card.DetailsRelativeContainer><Card.ActionButton clickActionButton={clickActionButton} isDisabled={isDisabled} isSelected={isSelected}>{isSelected ? display.selectedInfo : isDisabled ? display.disabledInfo : display.notSelectedInfo}</Card.ActionButton></>}</Card.Container>;
};
