"use strict";
import { Group } from "@visx/group";
import { LinePath } from "@visx/shape";
import { useTheme } from "lib/styled-components";
import React from "react";
function LineChart({
  data,
  getX,
  getY,
  marginTop,
  curve,
  color,
  strokeWidth,
  width,
  height,
  children
}) {
  const theme = useTheme();
  return <svg width={width} height={height}><Group top={marginTop}><LinePath
    curve={curve}
    stroke={color ?? theme.accent1}
    strokeWidth={strokeWidth}
    data={data}
    x={getX}
    y={getY}
  /></Group>{children}</svg>;
}
export default React.memo(LineChart);
