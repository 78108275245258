"use strict";
import { useMemo } from "react";
import { useSupportedChainIdWithConnector } from "uniswap/src/features/chains/hooks/useSupportedChainId";
import { useAccount as useAccountWagmi, useChainId } from "wagmi";
export function useAccount() {
  const { chainId, ...rest } = useAccountWagmi();
  const fallbackChainId = useChainId();
  const supportedChainId = useSupportedChainIdWithConnector(chainId ?? fallbackChainId, rest.connector);
  return useMemo(
    () => ({
      ...rest,
      chainId: supportedChainId
    }),
    [rest, supportedChainId]
  );
}
