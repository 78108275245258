import { Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Wifi, AnimatedWifi] = createIcon({
  name: "Wifi",
  getIcon: (props) => <Svg viewBox="0 0 20 20" fill="none" {...props}><Path
    d="M12.4993 14.1825C12.3234 14.1825 12.1468 14.1275 11.9959 14.0125C10.8518 13.1442 9.1476 13.1442 8.00343 14.0125C7.63593 14.2917 7.11416 14.2183 6.83583 13.8525C6.55749 13.4858 6.62928 12.9633 6.99594 12.685C8.72428 11.3725 11.2751 11.3725 13.0034 12.685C13.3701 12.9633 13.4417 13.4858 13.1633 13.8525C13 14.0683 12.7518 14.1825 12.4993 14.1825ZM15.6318 11.5675C15.9318 11.2183 15.8918 10.6925 15.5443 10.3925C14.0052 9.07001 12.0367 8.34167 10.0009 8.34167C7.96507 8.34167 5.99603 9.07001 4.45769 10.3925C4.10936 10.6925 4.06917 11.2183 4.37001 11.5675C4.66917 11.9175 5.19597 11.9558 5.54514 11.6558C6.78014 10.5933 8.36339 10.0075 10.0017 10.0075C11.6401 10.0075 13.2227 10.5925 14.4585 11.6558C14.616 11.7908 14.8092 11.8575 15.0017 11.8575C15.2342 11.8575 15.4676 11.76 15.6318 11.5675ZM18.121 9.25832C18.4277 8.91582 18.3984 8.38833 18.0559 8.08166C15.8376 6.095 12.9768 5 10.0001 5C7.02343 5 4.1626 6.09416 1.94427 8.08166C1.60177 8.38833 1.5725 8.91582 1.87916 9.25832C2.18666 9.60165 2.71425 9.62916 3.05592 9.32332C4.96842 7.61082 7.43426 6.66751 10.0001 6.66751C12.5659 6.66751 15.0318 7.61082 16.9443 9.32332C17.1026 9.46582 17.3018 9.53583 17.5001 9.53583C17.7284 9.53499 17.9569 9.44165 18.121 9.25832ZM10.0001 14.7917C9.42509 14.7917 8.95843 15.2583 8.95843 15.8333C8.95843 16.4083 9.42509 16.875 10.0001 16.875C10.5751 16.875 11.0418 16.4083 11.0418 15.8333C11.0418 15.2583 10.5751 14.7917 10.0001 14.7917Z"
    fill={"currentColor"}
  /></Svg>,
  defaultFill: "#7D7D7D"
});
