import { G, Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Pools, AnimatedPools] = createIcon({
  name: "Pools",
  getIcon: (props) => <Svg viewBox="0 0 16 16" fill="none" {...props}><G id="pools"><Path
    id="Vector"
    d="M4.86473 6.13208H9.66471V3.46533C9.66471 2.28712 10.6198 1.33203 11.798 1.33203C12.9762 1.33203 13.9314 2.28712 13.9314 3.46533H12.8647C12.8647 2.87624 12.3871 2.39873 11.798 2.39873C11.2089 2.39873 10.7314 2.87624 10.7314 3.46533V9.33208H9.66471V7.19878H4.86473V8.26541H3.79803V3.46538C3.79803 2.28716 4.75321 1.33208 5.93143 1.33208C7.10965 1.33208 8.06473 2.28716 8.06473 3.46538H6.99803C6.99803 2.87628 6.52053 2.39877 5.93143 2.39877C5.34233 2.39877 4.86473 2.87628 4.86473 3.46538V6.13208ZM2.39616 10.8939L2 9.90355C3.90046 9.14334 5.98515 9.14334 8.23338 9.89275C10.2518 10.5655 12.0782 10.5655 13.7332 9.90355L14.1294 10.8939C12.229 11.6541 10.1442 11.6541 7.89605 10.9046C5.87755 10.2318 4.05125 10.2318 2.39616 10.8939ZM2.39616 13.5604L2 12.5701C3.90046 11.8099 5.98515 11.8099 8.23338 12.5593C10.2518 13.2321 12.0782 13.2321 13.7332 12.5701L14.1294 13.5604C12.229 14.3206 10.1442 14.3206 7.89605 13.5712C5.87755 12.8985 4.05125 12.8985 2.39616 13.5604Z"
    fill={"currentColor"}
  /></G></Svg>,
  defaultFill: "#9B9B9B"
});
