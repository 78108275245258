"use strict";
import { partitionMixedRouteByProtocol, Protocol } from "@uniswap/router-sdk";
import { CurrencyAmount, Percent } from "@uniswap/sdk-core";
import { Pair } from "@uniswap/v2-sdk";
import { Pool } from "@uniswap/v3-sdk";
import { useAccount } from "hooks/useAccount";
import useGasPrice from "hooks/useGasPrice";
import { useStablecoinAmountFromFiatValue } from "hooks/useStablecoinAmountFromFiatValue";
import { useUSDPrice } from "hooks/useUSDPrice";
import JSBI from "jsbi";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { useMemo } from "react";
import { chainSupportsGasEstimates, isL2ChainId } from "uniswap/src/features/chains/utils";
import { logger } from "utilities/src/logger/logger";
const DEFAULT_AUTO_SLIPPAGE = new Percent(5, 1e3);
const V3_SWAP_BASE_GAS_ESTIMATE = 1e5;
const V2_SWAP_BASE_GAS_ESTIMATE = 135e3;
const V3_SWAP_HOP_GAS_ESTIMATE = 7e4;
const V2_SWAP_HOP_GAS_ESTIMATE = 5e4;
function guesstimateGas(trade) {
  if (trade) {
    let gas = 0;
    for (const { route } of trade.swaps) {
      if (route.protocol === Protocol.V2) {
        gas += V2_SWAP_BASE_GAS_ESTIMATE + route.pools.length * V2_SWAP_HOP_GAS_ESTIMATE;
      } else if (route.protocol === Protocol.V3) {
        gas += V3_SWAP_BASE_GAS_ESTIMATE + route.pools.length * V3_SWAP_HOP_GAS_ESTIMATE;
      } else if (route.protocol === Protocol.MIXED) {
        const sections = partitionMixedRouteByProtocol(route);
        gas += sections.reduce((gas2, section) => {
          if (section.every((pool) => pool instanceof Pool)) {
            return gas2 + V3_SWAP_BASE_GAS_ESTIMATE + section.length * V3_SWAP_HOP_GAS_ESTIMATE;
          } else if (section.every((pool) => pool instanceof Pair)) {
            return gas2 + V2_SWAP_BASE_GAS_ESTIMATE + (section.length - 1) * V2_SWAP_HOP_GAS_ESTIMATE;
          } else {
            logger.warn("useAutoSlippageTolerance", "guesstimateGas", "Invalid section", { section });
            return gas2;
          }
        }, 0);
      } else {
        gas += V3_SWAP_BASE_GAS_ESTIMATE + route.pools.length * V3_SWAP_HOP_GAS_ESTIMATE;
      }
    }
    return gas;
  }
  return void 0;
}
const MIN_AUTO_SLIPPAGE_TOLERANCE = DEFAULT_AUTO_SLIPPAGE;
const MAX_AUTO_SLIPPAGE_TOLERANCE = new Percent(5, 100);
export default function useClassicAutoSlippageTolerance(trade) {
  const { chainId } = useAccount();
  const onL2 = isL2ChainId(chainId);
  const outputUSD = useUSDPrice(trade?.outputAmount);
  const outputDollarValue = useStablecoinAmountFromFiatValue(outputUSD.data);
  const supportsGasEstimate = useMemo(() => chainId && chainSupportsGasEstimates(chainId), [chainId]);
  const gasEstimateUSD = useStablecoinAmountFromFiatValue(supportsGasEstimate ? trade?.gasUseEstimateUSD : void 0) ?? null;
  const skipNativeEstimate = Boolean(gasEstimateUSD || !trade);
  const nativeGasPrice = useGasPrice(
    /* skip= */
    skipNativeEstimate
  );
  const nativeCurrency = useNativeCurrency(chainId);
  const gasEstimate = guesstimateGas(trade);
  const nativeGasCost = nativeGasPrice && typeof gasEstimate === "number" ? JSBI.multiply(nativeGasPrice, JSBI.BigInt(gasEstimate)) : void 0;
  const gasCostUSD = useUSDPrice(
    nativeCurrency && nativeGasCost ? CurrencyAmount.fromRawAmount(nativeCurrency, nativeGasCost) : void 0
  );
  const gasCostStablecoinAmount = useStablecoinAmountFromFiatValue(gasCostUSD.data);
  return useMemo(() => {
    if (!trade || onL2) {
      return DEFAULT_AUTO_SLIPPAGE;
    }
    const dollarCostToUse = chainId && chainSupportsGasEstimates(chainId) && gasEstimateUSD ? gasEstimateUSD : gasCostStablecoinAmount;
    if (outputDollarValue && dollarCostToUse) {
      const fraction = dollarCostToUse.asFraction.divide(outputDollarValue.asFraction);
      const result = new Percent(fraction.numerator, fraction.denominator);
      if (result.greaterThan(MAX_AUTO_SLIPPAGE_TOLERANCE)) {
        return MAX_AUTO_SLIPPAGE_TOLERANCE;
      }
      if (result.lessThan(MIN_AUTO_SLIPPAGE_TOLERANCE)) {
        return MIN_AUTO_SLIPPAGE_TOLERANCE;
      }
      return result;
    }
    return DEFAULT_AUTO_SLIPPAGE;
  }, [trade, onL2, chainId, gasEstimateUSD, gasCostStablecoinAmount, outputDollarValue]);
}
