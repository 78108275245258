"use strict";
import { useHeaderDateFormatter } from "components/Charts/hooks";
import { PROTOCOL_LEGEND_ELEMENT_ID } from "components/Charts/types";
import { getProtocolColor, getProtocolName } from "graphql/data/util";
import { useTheme } from "lib/styled-components";
import { EllipsisTamaguiStyle } from "theme/components";
import { ThemedText } from "theme/components/text";
import { Flex, Text, styled } from "ui/src";
import { NumberType, useFormatter } from "utils/formatNumbers";
const ProtocolLegendWrapper = styled(Flex, {
  position: "absolute",
  right: 0,
  py: "$spacing4",
  px: "$spacing12",
  gap: "$gap12",
  pointerEvents: "none",
  variants: {
    hover: {
      true: {
        right: "unset",
        p: "$spacing8",
        gap: "$gap6",
        borderRadius: "$rounded12",
        border: "1px solid",
        borderColor: "$surface3",
        backgroundColor: "$surface2",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.02), 0px 1px 6px 2px rgba(0, 0, 0, 0.03)",
        zIndex: "$tooltip"
      }
    }
  }
});
function ProtocolLegend({ protocolData }) {
  const { formatFiatPrice } = useFormatter();
  const theme = useTheme();
  return <ProtocolLegendWrapper id={PROTOCOL_LEGEND_ELEMENT_ID} hover={true}>{protocolData?.map(({ value, protocol }) => {
    const display = value ? formatFiatPrice({ price: value, type: NumberType.ChartFiatValue }) : null;
    return !!display && <Flex row gap={8} justifyContent="flex-end" key={protocol + "_blip"} width="max-content"><Text variant="body4" textAlign="right" color="$neutral2" lineHeight={12}>{getProtocolName(protocol)}</Text><Flex
      borderRadius="$rounded4"
      width={12}
      height={12}
      backgroundColor={getProtocolColor(protocol, theme)}
    /><Text variant="body4" textAlign="right" lineHeight={12} {...EllipsisTamaguiStyle}>{display}</Text></Flex>;
  }).reverse()}</ProtocolLegendWrapper>;
}
function HeaderValueDisplay({ value, valueFormatterType = NumberType.ChartFiatValue }) {
  const { formatFiatPrice } = useFormatter();
  if (typeof value !== "number" && typeof value !== "undefined") {
    return <>{value}</>;
  }
  return <Text variant="heading2" {...EllipsisTamaguiStyle}>{formatFiatPrice({ price: value, type: valueFormatterType })}</Text>;
}
function HeaderTimeDisplay({ time, timePlaceholder }) {
  const headerDateFormatter = useHeaderDateFormatter();
  return <ThemedText.SubHeader color="neutral2">{time ? headerDateFormatter(time) : timePlaceholder}</ThemedText.SubHeader>;
}
export function ChartHeader({
  value,
  valueFormatterType,
  time,
  timePlaceholder,
  protocolData,
  additionalFields
}) {
  const isHovered = !!time;
  return <Flex
    row
    position="absolute"
    width="100%"
    gap="$gap8"
    alignItems="flex-start"
    animation="fast"
    zIndex="$tooltip"
    id="chart-header"
  ><Flex position="absolute" gap="$gap4" pb={14} pointerEvents="none" width="70%"><HeaderValueDisplay value={value} valueFormatterType={valueFormatterType} /><Flex row gap="$gap8" {...EllipsisTamaguiStyle}>{additionalFields}<HeaderTimeDisplay time={time} timePlaceholder={timePlaceholder} /></Flex></Flex>{isHovered && protocolData && <ProtocolLegend protocolData={protocolData} />}</Flex>;
}
