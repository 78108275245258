import { Path, Svg } from "react-native-svg";
import { createIcon } from "../factories/createIcon";
export const [Shuffle, AnimatedShuffle] = createIcon({
  name: "Shuffle",
  getIcon: (props) => <Svg viewBox="0 0 20 20" fill="none" {...props}><Path
    d="M18.269 14.485C18.2265 14.5875 18.1657 14.6791 18.0882 14.7566L16.4224 16.4224C16.2599 16.5849 16.0465 16.6666 15.8332 16.6666C15.6198 16.6666 15.4065 16.5849 15.244 16.4224C14.9181 16.0966 14.9181 15.5699 15.244 15.2441L15.4882 14.9999H14.534C13.5157 14.9999 12.5682 14.5441 11.9315 13.7491L6.76569 7.29239C6.44819 6.89489 5.97399 6.66739 5.46482 6.66739H2.49984C2.039 6.66739 1.6665 6.29406 1.6665 5.83406C1.6665 5.37406 2.039 5.00072 2.49984 5.00072H5.46564C6.48397 5.00072 7.43151 5.45654 8.06818 6.25154L13.234 12.7082C13.5515 13.1057 14.0257 13.3332 14.5349 13.3332H15.489L15.2448 13.0891C14.919 12.7633 14.919 12.2365 15.2448 11.9107C15.5706 11.5849 16.0973 11.5849 16.4232 11.9107L18.089 13.5766C18.1665 13.6541 18.2273 13.7457 18.2698 13.8482C18.3531 14.0515 18.3531 14.2816 18.269 14.485ZM11.6657 8.74991C11.9107 8.74991 12.1524 8.64324 12.3165 8.43741L13.234 7.29158C13.5515 6.89408 14.0257 6.66658 14.5349 6.66658H15.489L15.2448 6.91072C14.919 7.23655 14.919 7.76327 15.2448 8.0891C15.4073 8.2516 15.6207 8.33324 15.834 8.33324C16.0473 8.33324 16.2607 8.2516 16.4232 8.0891L18.089 6.42325C18.1665 6.34575 18.2273 6.25414 18.2698 6.15164C18.354 5.94831 18.354 5.71818 18.2698 5.51484C18.2273 5.41234 18.1665 5.32074 18.089 5.24324L16.4232 3.57738C16.0973 3.25155 15.5706 3.25155 15.2448 3.57738C14.919 3.90322 14.919 4.42993 15.2448 4.75577L15.489 4.99991H14.5349C13.5165 4.99991 12.569 5.45572 11.9331 6.25072L11.0165 7.39574C10.7281 7.75491 10.7873 8.27991 11.1457 8.56741C11.2998 8.68991 11.4832 8.74991 11.6657 8.74991ZM7.68315 11.5624L6.76569 12.7082C6.44819 13.1057 5.97399 13.3332 5.46482 13.3332H2.49984C2.039 13.3332 1.6665 13.7066 1.6665 14.1666C1.6665 14.6266 2.039 14.9999 2.49984 14.9999H5.46564C6.48397 14.9999 7.43153 14.5441 8.06736 13.7491L8.98401 12.6041C9.27234 12.2449 9.21315 11.7199 8.85482 11.4324C8.49315 11.1457 7.96981 11.2041 7.68315 11.5624Z"
    fill={"currentColor"}
    stroke="currentColor"
    strokeWidth="0.5"
  /></Svg>,
  defaultFill: "#9B9B9B"
});
