"use strict";
import { useAccount } from "hooks/useAccount";
import useSelectChain from "hooks/useSelectChain";
import { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useMultichainContext } from "state/multichain/useMultichainContext";
import { Flex } from "ui/src";
import { NetworkFilter } from "uniswap/src/components/network/NetworkFilter";
import { getChainInfo } from "uniswap/src/features/chains/chainInfo";
import { useEnabledChains } from "uniswap/src/features/chains/hooks/useEnabledChains";
import { useIsSupportedChainIdCallback } from "uniswap/src/features/chains/hooks/useSupportedChainId";
export const ChainSelector = ({ hideArrow }) => {
  const account = useAccount();
  const { chainId, setSelectedChainId } = useMultichainContext();
  const popoverRef = useRef(null);
  const isSupportedChain = useIsSupportedChainIdCallback();
  const selectChain = useSelectChain();
  const [searchParams, setSearchParams] = useSearchParams();
  const { chains } = useEnabledChains();
  const onSelectChain = useCallback(
    async (targetChainId) => {
      if (!targetChainId) {
        setSelectedChainId(targetChainId);
      } else {
        await selectChain(targetChainId);
      }
      searchParams.delete("inputCurrency");
      searchParams.delete("outputCurrency");
      searchParams.delete("value");
      searchParams.delete("field");
      targetChainId && searchParams.set("chain", getChainInfo(targetChainId).interfaceName);
      setSearchParams(searchParams);
      popoverRef.current?.close();
    },
    [setSelectedChainId, selectChain, searchParams, setSearchParams]
  );
  const isUnsupportedConnectedChain = account.isConnected && !isSupportedChain(account.chainId);
  return <Flex px="$spacing8"><NetworkFilter
    selectedChain={chainId ?? null}
    onPressChain={onSelectChain}
    showUnsupportedConnectedChainWarning={isUnsupportedConnectedChain}
    hideArrow={hideArrow}
    chainIds={chains}
    styles={{
      sticky: true
    }}
  /></Flex>;
};
