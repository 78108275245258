"use strict";
import { useEffect, useState } from "react";
export var ScrollDirection = /* @__PURE__ */ ((ScrollDirection2) => {
  ScrollDirection2["UP"] = "up";
  ScrollDirection2["DOWN"] = "down";
  return ScrollDirection2;
})(ScrollDirection || {});
export function useScroll({ enabled = true } = {}) {
  const [direction, setDirection] = useState();
  const [isScrolledDown, setIsScrolledDown] = useState(false);
  const [height, setHeight] = useState(window.scrollY);
  useEffect(() => {
    let previousScrollPosition = 0;
    let currentScrollPosition = 0;
    const scrollListener = () => {
      if (!enabled) {
        return;
      }
      setIsScrolledDown(window.scrollY > 0);
      if (window.scrollY >= 0) {
        setHeight(window.scrollY);
        currentScrollPosition = window.scrollY;
      }
      if (previousScrollPosition < currentScrollPosition) {
        setDirection("down" /* DOWN */);
      } else if (previousScrollPosition > currentScrollPosition) {
        setDirection("up" /* UP */);
      }
      previousScrollPosition = currentScrollPosition;
    };
    window.addEventListener("scroll", scrollListener);
    return () => window.removeEventListener("scroll", scrollListener);
  }, [enabled]);
  return { direction, isScrolledDown, height };
}
