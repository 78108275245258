"use strict";
import { useTokenWarningColor, useTokenWarningTextColor } from "hooks/useTokenWarningColor";
import styled from "lib/styled-components";
import { AlertTriangle, Slash } from "react-feather";
import { Text } from "rebass";
const Label = styled.div`
  padding: 4px 4px;
  font-size: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  color: ${({ color }) => color};
  display: inline-flex;
  align-items: center;
`;
const Title = styled(Text)`
  margin-right: 5px;
  font-weight: 535;
  font-size: 12px;
`;
export default function TokenSafetyLabel({ level, canProceed, children }) {
  return <Label color={useTokenWarningTextColor(level)} backgroundColor={useTokenWarningColor(level)}><Title marginRight="5px">{children}</Title>{canProceed ? <AlertTriangle strokeWidth={2.5} size="14px" /> : <Slash strokeWidth={2.5} size="14px" />}</Label>;
}
