"use strict";
import { Button, Flex, SpinningLoader, WidthAnimator } from "ui/src";
import { iconSizes } from "ui/src/theme";
export function LoaderButton({
  buttonKey,
  loading,
  size,
  animation,
  children,
  loaderColor,
  ...rest
}) {
  return <Button
    key={`LoaderButton-animation-${buttonKey}`}
    size={size ?? "large"}
    animation={animation ?? "fastHeavy"}
    {...rest}
  ><Flex row alignItems="center" gap="$spacing8"><WidthAnimator open={loading} height={iconSizes.icon24}><Flex justifyContent="center" alignItems="center" width={iconSizes.icon24}><SpinningLoader color={loaderColor ?? "$white"} /></Flex></WidthAnimator>{children}</Flex></Button>;
}
