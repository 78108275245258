import { Text } from "ui/src/components/text";
const gradientStyle = `
  .uniswapx-gradient {
    color: #4673fa;

    @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
      background-image: linear-gradient(0deg, #4673fa -101.76%, #9646fa 101.76%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;
export function UniswapXText({ children, ...props }) {
  return <><style>{gradientStyle}</style><Text {...props} className="uniswapx-gradient">{children}</Text></>;
}
